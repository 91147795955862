import React from "react"
import { Link } from "gatsby"

// Import Images
import Logo from '../images/rooam-icon.svg'

// Import Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import CollapseFAQ from "../components/collapseFAQ"
import Features from "../components/features"

const FAQ = () => {

  return (
    <Layout>
      <SEO title="FAQ's - Rooam" />
      <div className="section more-top no-bottom">
        <div className="container medium text-center">
          <h1 className="text-white no-top-bottom">Common questions</h1>
        </div>
      </div>

      <div className="section less-top">
        <CollapseFAQ/>
      </div>

      <div className="section no-top">
        <Features/>
      </div>

      <div className="section no-top">
        <div className="container text-center">
          <div className="peak-wrap no-top">
            <div className="icon logo">
              <Link to="/"><img src={Logo} alt="Rooam Logo" /></Link>
            </div>
          </div>
          <h2 className="no-top-bottom">
            <span className="text-white">Rooam is built for your business.</span><br/>
            <span className="text-grey">Go live today.</span>
          </h2>
          <Link to="/request-demo/" className="btn light no-bottom-margin">Request Demo</Link>
          <a className="btn dark no-bottom-margin" href="mailto:business@rooam.co" target="_blank" rel="noopener noreferrer">
            Contact Sales
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default FAQ
