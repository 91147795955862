import React from "react"

export default function Features() {

  return (
    <div className="section no-top-bottom">
      <div className="container columns">
        <div className="row clearfix">
          <div className="column break-full">
            <div className="padding">
              <div className="feature">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                </span>
                <p className="text-light-grey no-top-bottom">
                  <span className="text-white font-weight-700">Increase Revenue &amp; Speed Of Service</span> &mdash; With Rooam, bartenders and servers can focus on serving guests instead of running cards. On average Rooam users spend 30% more and leave 25% higher tips.
                </p>
              </div>
            </div>
          </div>
          <div className="column break-full">
            <div className="padding">
              <div className="feature">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                </span>
                <p className="text-light-grey no-top-bottom">
                  <span className="text-white font-weight-700">Direct POS Integration</span> &mdash; Rooam integrates directly into existing POS systems. No added fees for additional hardware or complicated ordering structures.
                </p>
              </div>
            </div>
          </div>
          <div className="column break-full">
            <div className="padding">
              <div className="feature">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                </span>
                <p className="text-light-grey no-top-bottom">
                  <span className="text-white font-weight-700">Increase Guest Confidence</span> &mdash; Local officials, staff, and guests want significant safety measures in place in response to the COVID-19 pandemic.
                </p>
              </div>
            </div>
          </div>
          <div className="column break-full">
            <div className="padding">
              <div className="feature">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                </span>
                <p className="text-light-grey no-top-bottom">
                  <span className="text-white font-weight-700">Offer Apple Pay &amp; Google Pay</span> &mdash; Accept Apple Pay and Google Pay with no extra hardware or fees. (Venmo &amp; PayPal coming soon)
                </p>
              </div>
            </div>
          </div>
          <div className="column break-full">
            <div className="padding">
              <div className="feature">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                </span>
                <p className="text-light-grey no-top-bottom">
                  <span className="text-white font-weight-700">Increased Payment Security, Reduced Liability And Chargebacks</span> &mdash; Rooam transactions are validated Level 1 PCI DSS compliant. Your guests’ personal payment information will never touch your servers.
                </p>
              </div>
            </div>
          </div>
          <div className="column break-full">
            <div className="padding">
              <div className="feature">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                </span>
                <p className="text-light-grey no-top-bottom">
                  <span className="text-white font-weight-700">Give Guests Contactless Payments</span> &mdash; 83% of guests surveyed said when restaurants reopen they should have the option to pay using Rooam to limit contact between themselves, servers, and other guests.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

