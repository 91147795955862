import React, { Component } from 'react';

// Import Reveal Effects
import Fade from 'react-reveal/Fade';

class CollapseFAQ extends Component {

  state = {
    isCollapseOneActive: false,
    isCollapseTwoActive: false,
    isCollapseThreeActive: false,
    isCollapseFourActive: false,
    isCollapseFiveActive: false,
    isCollapseSixActive: false,
    isCollapseSevenActive: false,
    isCollapseEightActive: false,
    isCollapseNineActive: false,
  }
  
  render() {

    const isOneActive = this.state.isCollapseOneActive;
    const isTwoActive = this.state.isCollapseTwoActive;
    const isThreeActive = this.state.isCollapseThreeActive;
    const isFourActive = this.state.isCollapseFourActive;
    const isFiveActive = this.state.isCollapseFiveActive;
    const isSixActive = this.state.isCollapseSixActive;
    const isSevenActive = this.state.isCollapseSevenActive;
    const isEightActive = this.state.isCollapseEightActive;
    const isNineActive = this.state.isCollapseNineActive;

    return (
      <div className="section no-top-bottom">
        <div className="container medium">
          <Fade bottom distance={'1em'} duration={500} cascade>
            <ul className="collapse no-bottom">

              <li className={ isOneActive ? "active" : ""}>
                <div className="collapse-title no-select" onClick={ () => { this.setState({isCollapseOneActive: !isOneActive}); }} onKeyDown={ () => { this.setState({isCollapseOneActive: !isOneActive}); }} role="link" tabIndex={0}>
                  <p className="text-white font-weight-500 no-top-bottom">
                    What POS systems does Rooam integrate with?
                  </p>
                  <span className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
                  </span>
                </div>
                <div className="collapse-body">
                  <p className="text-light-grey">Rooam has more POS integration partnerships than any other contactless payment solution in the country. Currently, Rooam integrates with:</p>
                  <ul className="bulleted-list text-light-grey">
                    <li>
                      <p className="text-light-grey no-top-bottom">Micros RES 3700</p>
                    </li>
                    <li>
                      <p className="text-light-grey no-top-bottom">Micros Simphony FE</p>
                    </li>
                    <li>
                      <p className="text-light-grey no-top-bottom">Micros Simphony</p>
                    </li>
                    <li>
                      <p className="text-light-grey no-top-bottom">NCR Aloha</p>
                    </li>
                    <li>
                      <p className="text-light-grey no-top-bottom">Toast</p>
                    </li>
                    <li>
                      <p className="text-light-grey no-top-bottom">Upserve/Breadcrumb</p>
                    </li>
                    <li>
                      <p className="text-light-grey no-top-bottom">POSitouch</p>
                    </li>
                    <li>
                      <p className="text-light-grey no-top-bottom">Focus</p>
                    </li>
                    <li>
                      <p className="text-light-grey no-top-bottom">Dinerware</p>
                    </li>
                    <li>
                      <p className="text-light-grey no-top-bottom">Squirrel Systems and more!</p>
                    </li>
                  </ul>
                  <p className="text-light-grey">We are always looking to add additional integrations. If your business uses a different POS system or you are a POS partner and would like to discuss integration, please contact us at <a className="text-link-green" href="mailto:business@rooam.co" target="_blank" rel="noopener noreferrer">business@rooam.co</a>.</p>
                </div>
              </li>

              <li className={ isTwoActive ? "active" : ""}>
                <div className="collapse-title no-select" onClick={ () => { this.setState({isCollapseTwoActive: !isTwoActive}); }} onKeyDown={ () => { this.setState({isCollapseTwoActive: !isTwoActive}); }} role="link" tabIndex={0}>
                  <p className="text-white font-weight-500 no-top-bottom">
                    Can Rooam integrate with my processor?
                  </p>
                  <span className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
                  </span>
                </div>
                <div className="collapse-body">
                  <p className="text-light-grey">Yes, Rooam can integrate with various gateways and processors. Please reach out to <a className="text-link-green" href="mailto:business@rooam.co" target="_blank" rel="noopener noreferrer">business@rooam.co</a> to discuss specific payment processor integrations.</p>
                </div>
              </li>

              <li className={ isThreeActive ? "active" : ""}>
                <div className="collapse-title no-select" onClick={ () => { this.setState({isCollapseThreeActive: !isThreeActive}); }} onKeyDown={ () => { this.setState({isCollapseThreeActive: !isThreeActive}); }} role="link" tabIndex={0}>
                  <p className="text-white font-weight-500 no-top-bottom">
                    What happens if a guest walks out?
                  </p>
                  <span className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
                  </span>
                </div>
                <div className="collapse-body">
                  <p className="text-light-grey">Rooam offers 100% walk-out protection as a payment method is required to open a Rooam tab. Therefore, all open tabs are automatically closed at the end of the night against the guest’s payment method on file with a 20% gratuity.</p>
                </div>
              </li>

              <li className={ isFourActive ? "active" : ""}>
                <div className="collapse-title no-select" onClick={ () => { this.setState({isCollapseFourActive: !isFourActive}); }} onKeyDown={ () => { this.setState({isCollapseFourActive: !isFourActive}); }} role="link" tabIndex={0}>
                  <p className="text-white font-weight-500 no-top-bottom">
                    Do guests tip higher or lower with Rooam?
                  </p>
                  <span className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
                  </span>
                </div>
                <div className="collapse-body">
                  <p className="text-light-grey">Nationally, the average tip percentage on Rooam is over 19.2%!</p>
                </div>
              </li>

              <li className={ isFiveActive ? "active" : ""}>
                <div className="collapse-title no-select" onClick={ () => { this.setState({isCollapseFiveActive: !isFiveActive}); }} onKeyDown={ () => { this.setState({isCollapseFiveActive: !isFiveActive}); }} role="link" tabIndex={0}>
                  <p className="text-white font-weight-500 no-top-bottom">
                    How fast can Rooam implement the solution?
                  </p>
                  <span className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
                  </span>
                </div>
                <div className="collapse-body">
                  <p className="text-light-grey">A typical implementation lasts 60-90 minutes max, and we can do same-day installs. Cloud-based POS system installs (i.e Toast, Upserve) take no longer than 30 minutes.</p>
                </div>
              </li>

              <li className={ isSixActive ? "active" : ""}>
                <div className="collapse-title no-select" onClick={ () => { this.setState({isCollapseSixActive: !isSixActive}); }} onKeyDown={ () => { this.setState({isCollapseSixActive: !isSixActive}); }} role="link" tabIndex={0}>
                  <p className="text-white font-weight-500 no-top-bottom">
                    Is Rooam PCI compliant?
                  </p>
                  <span className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
                  </span>
                </div>
                <div className="collapse-body">
                  <p className="text-light-grey">Yes, Rooam is an SAQ-A PCI certified company. All payment methods are tokenized and vaulted with our gateway partners, meaning no financial information ever touches Rooam’s server or your POS system. Rooam helps decrease your data breach liability!</p>
                </div>
              </li>

              <li className={ isSevenActive ? "active" : ""}>
                <div className="collapse-title no-select" onClick={ () => { this.setState({isCollapseSevenActive: !isSevenActive}); }} onKeyDown={ () => { this.setState({isCollapseSevenActive: !isSevenActive}); }} role="link" tabIndex={0}>
                  <p className="text-white font-weight-500 no-top-bottom">
                    Do my guests have to download an app?
                  </p>
                  <span className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
                  </span>
                </div>
                <div className="collapse-body">
                  <p className="text-light-grey">Rooam offers a web-based solution as well, in which a QR code is placed on the receipt. This allows the guest to enter Rooam’s contactless payment solution using their native phone camera without having to download an app.</p>
                </div>
              </li>

              <li className={ isEightActive ? "active" : ""}>
                <div className="collapse-title no-select" onClick={ () => { this.setState({isCollapseEightActive: !isEightActive}); }} onKeyDown={ () => { this.setState({isCollapseEightActive: !isEightActive}); }} role="link" tabIndex={0}>
                  <p className="text-white font-weight-500 no-top-bottom">
                    What’s the difference between the Rooam App and Rooam Pay solutions?
                  </p>
                  <span className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
                  </span>
                </div>
                <div className="collapse-body">
                  <p className="text-light-grey">The Rooam app is an ecosystem of bars, restaurants, venues, allowing a guest to open and view their check. A payment method is on file, eliminating walk-outs.</p>
                  <p className="text-light-grey">Rooam Pay is our web-based solution, which is very popular with sit-down restaurants, allowing a guest to pay for their check using a QR code, without needing to download an app.</p>
                  <p className="text-light-grey">Rooam is currently the only contactless payment platform that offers both an app and a web-based payment solution. Most of our partners implement both solutions.</p>
                </div>
              </li>

              <li className={ isNineActive ? "active" : ""}>
                <div className="collapse-title no-select" onClick={ () => { this.setState({isCollapseNineActive: !isNineActive}); }} onKeyDown={ () => { this.setState({isCollapseNineActive: !isNineActive}); }} role="link" tabIndex={0}>
                  <p className="text-white font-weight-500 no-top-bottom">
                    Can Rooam white-label into existing apps?
                  </p>
                  <span className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
                  </span>
                </div>
                <div className="collapse-body">
                  <p className="text-light-grey">Yes, we are happy to white-label our payment functionality into your existing app. Please contact us at <a className="text-link-green" href="mailto:business@rooam.co" target="_blank" rel="noopener noreferrer">business@rooam.co</a> to discuss further.</p>
                </div>
              </li>

            </ul>
          </Fade>
        </div>
      </div>
    );
  }
}

export default CollapseFAQ

